export const actions = {
  CHANGE_USER_STATUS: 'user/CHANGE_USER_STATUS',
  LOAD_USER: 'user/LOAD_USER',
  LOAD_USER_RESULT: 'user/LOAD_USER_RESULT',
  RESET_USER: 'user/RESET_USER',
  DELETE_USER: 'user/DELETE_USER',
}

export function changeUserStatus(payload) {
  return {
    type: actions.CHANGE_USER_STATUS,
    payload,
  }
}

export function deleteUser(payload) {
  return {
    type: actions.DELETE_USER,
    payload,
  }
}

export function loadUser(payload) {
  return {
    type: actions.LOAD_USER,
    payload,
  }
}

export function resetUser() {
  return {
    type: actions.RESET_USER,
  }
}
