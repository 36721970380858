import { actions } from './actions'

const initialState = {
  data: {},
}

export default function classReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_CLASS_RESULT:
      return { ...state, data: action.payload.data }
    case actions.RESET_CLASS:
      return { ...state, data: {} }
    default:
      return state
  }
}
