export const actions = {
  LOAD_MAIN_TOPIC_REQUEST: 'admin/LOAD_MAIN_TOPIC_REQUEST',
  LOAD_MAIN_TOPIC_RESULT: 'admin/LOAD_MAIN_TOPIC_RESULT',
  RESET_MAIN_TOPIC: 'admin/RESET_MAIN_TOPIC',
}

export function loadMainTopic(payload) {
  return {
    type: actions.LOAD_MAIN_TOPIC_REQUEST,
    payload,
  }
}

export function resetMainTopic() {
  return {
    type: actions.RESET_MAIN_TOPIC,
  }
}
