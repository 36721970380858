import { all, takeEvery, put, call } from 'redux-saga/effects'
import menuApi from 'services/menu'
import { actions } from './actions'

export function* loadMenu(courseId) {
  try {
    const response = yield call(menuApi.loadMenu,courseId.payload)
    
    yield put({
      type: actions.LOAD_MENU_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_MENU_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_MENU_REQUEST, loadMenu)])
}
