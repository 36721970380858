export const actions = {
  LOAD_TOPIC_REQUEST: 'users/LOAD_TOPIC_REQUEST',
  LOAD_TOPIC_RESULT: 'users/LOAD_TOPIC_RESULT',
}

export function loadTopic(payload) {
  return {
    type: actions.LOAD_TOPIC_REQUEST,
    payload,
  }
}
