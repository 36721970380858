export const actions = {
  LOAD_CLASS_REQUEST: 'admin/LOAD_CLASS_REQUEST',
  LOAD_CLASS_RESULT: 'admin/LOAD_CLASS_RESULT',
  RESET_CLASS: 'admin/RESET_CLASS',
}

export function loadClass(payload) {
  return {
    type: actions.LOAD_CLASS_REQUEST,
    payload,
  }
}

export function resetClass() {
  return {
    type: actions.RESET_CLASS,
  }
}
