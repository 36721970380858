import { all, takeEvery, put, call } from 'redux-saga/effects'
import adminApi from 'services/admin'
import { actions } from './actions'

export function* loadPrintable(exerciseId) {
  try {
    const response = yield call(adminApi.getExercise, exerciseId.payload)

    yield put({
      type: actions.LOAD_PRINTABLE_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_PRINTABLE_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_PRINTABLE_REQUEST, loadPrintable)])
}
