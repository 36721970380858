import React from 'react'
import { Menu, Progress, Button } from 'antd'
import {
  ReloadOutlined,
  PlusOutlined,
  LeftOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons'
import { loadMenu } from 'redux/menu/actions'
import { loadTopics } from 'redux/topics/actions'
import { loadSubtopics } from 'redux/subTopics/actions'
import { loadLessons } from 'redux/lessons/actions'
import { loadLessonsProgress } from 'redux/lessonsProgress/actions'
import { loadExercises } from 'redux/exercises/actions'
import { loadMainTopic } from 'redux/mainTopic/actions'
import { loadClass } from 'redux/class/actions'
import { loadAdminTopic } from 'redux/adminTopic/actions'
import exerciseApi from 'services/exercises'
import lessonApi from 'services/lessons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styles from './style.module.scss'

/* eslint-disable*/
const { SubMenu } = Menu

@withRouter
@connect(
  ({ menu, subTopics, topic, lessons, lessonsProgress, exercise }) => ({
    menu,
    subTopics,
    topic,
    lessons,
    lessonsProgress,
    exercise,
  }),
  {
    loadMenu,
    loadTopics,
    loadSubtopics,
    loadLessons,
    loadLessonsProgress,
    loadExercises,
    loadMainTopic,
    loadClass,
    loadAdminTopic,
  },
)
class AppMenu extends React.Component {
  state = {
    data: [],
    keys: [],
    lessonKey: [],
    hoverLink: false,
    showMenu: true,
  }

  componentDidMount() {
    const {
      loadMenu,
      loadSubtopics,
      loadLessons,
      location: { pathname, hash },
    } = this.props

    if (
      pathname === '/main' ||
      pathname === '/mainTopics/create' ||
      pathname === '/mainTopics' ||
      pathname === '/classes/create' ||
      pathname === '/topics/create' ||
      pathname === '/classes' ||
      pathname === '/topics' ||
      pathname === '/footer/change' ||
      pathname === '/users' ||
      pathname === '/users/create' ||
      pathname === '/users/list'
    ) {
      loadMenu(process.env.REACT_APP_COURSE_ID || '2e033f1a-aa58-489f-85d2-0f92581b3624')
    } else if (pathname === '/adminpanel') {
      loadSubtopics(hash.split('?')[1])
    } else if (pathname === '/subtopics') {
      loadSubtopics(hash.slice(1))
    } else if (pathname === '/lessons') {
      loadLessons(hash.slice(1))
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      location: { pathname },
      loadLessonsProgress,
    } = nextProps
    const menu = nextProps.menu.data
    const subTopics = nextProps.subTopics.data
    const lessons = nextProps.lessons.data
    const exercise = nextProps.exercise.data

    if (
      menu.mainTopics &&
      JSON.stringify(menu.mainTopics) !== JSON.stringify(prevState.data) &&
      (pathname === '/main' ||
        pathname === '/mainTopics/create' ||
        pathname === '/mainTopics' ||
        pathname === '/classes/create' ||
        pathname === '/topics/create' ||
        pathname === '/classes' ||
        pathname === '/topics' ||
        pathname === '/footer/change' ||
        pathname === '/users' ||
        pathname === '/users/create' ||
        pathname === '/users/list')
    ) {
      const newKeys = []

      menu.mainTopics.forEach(element => {
        newKeys.push(`main&${element.id}`)
        element.classes.map(item => {
          newKeys.push(`class&${item.id}`)
        })
      })

      return {
        data: menu.mainTopics,
        keys: newKeys,
      }
    }
    if (pathname === '/adminpanel') {
      const newKeys = []

      subTopics.forEach(subTopic => {
        newKeys.push(`sub&${subTopic.id}`)
        subTopic.lessons.map(lesson => {
          newKeys.push(`lesson&${lesson.id}`)
          lesson.exercises.map(exercise => {
            newKeys.push(`exercise&${exercise.id}`)
          })
        })
      })

      return {
        data: subTopics,
        keys: newKeys,
      }
    }
    if (JSON.stringify(subTopics) !== JSON.stringify(prevState.data) && pathname === '/subtopics') {
      return {
        data: subTopics,
      }
    } else if (
      JSON.stringify(lessons) !== JSON.stringify(prevState.data) &&
      pathname === '/lessons'
    ) {
      const lessonsId = []

      lessons.forEach(lesson => {
        lessonsId.push(lesson.id)
      })

      loadLessonsProgress(lessonsId)

      return {
        data: lessons,
      }
    } else if (JSON.stringify(exercise.exerciseId) !== JSON.stringify(prevState.keys)) {
      const lessonKey = []
      const lessonsId = []

      lessons.forEach(lesson => {
        lessonsId.push(lesson.id)
        lesson.exercises.forEach(ex => {
          if (ex.id === exercise.exerciseId) {
            lessonKey.push(lesson.id)
          }
        })
      })

      return {
        keys: [exercise.exerciseId],
      }
    } else {
      return {}
    }
  }

  openTopic = object => {
    const { loadTopics } = this.props

    loadTopics(object.key)
  }

  openAdmin = e => {
    const {
      history,
      location: { hash },
    } = this.props

    history.push(`adminpanel#${e.key}?${hash.split('?')[1]}`)
  }

  openTopicAdmin = object => {
    const { history, loadAdminTopic } = this.props

    loadAdminTopic(object.key)
    history.push(`/topics#${object.key}`)
  }

  openLessons = lesson => {
    const { loadLessons, history } = this.props

    loadLessons(lesson.key)
    history.push(`/lessons#${lesson.key}`)
  }

  openExercise = exercise => {
    const { loadExercises } = this.props

    loadExercises(exercise.key)
  }

  restartLesson = e => {
    lessonApi.restartLesson(e.target.value)
    e.stopPropagation()
  }

  restartExercise = e => {
    exerciseApi.restartExercise(e.target.value)
    e.stopPropagation()
  }

  openMainTopic = e => {
    const { history, loadMainTopic } = this.props

    loadMainTopic(e.key)
    history.push(`/mainTopics#${e.key}`)
  }

  openUserList = () => {
    const { history } = this.props

    history.push(`/users/list`)
  }

  createMainTopic = () => {
    const { history } = this.props

    history.push(`/mainTopics/create`)
  }

  changeFooter = () => {
    const { history } = this.props

    history.push(`/footer/change`)
  }

  openClass = object => {
    const { history, loadClass } = this.props

    loadClass(object.key)
    history.push(`/classes#${object.key}`)
  }

  createClass = object => {
    const { history } = this.props
    const mainTopicId = object.currentTarget.value

    history.push(`/classes/create#mainTopic&${mainTopicId}`)
  }

  createTopic = object => {
    const { history } = this.props
    const classId = object.currentTarget.value

    history.push(`/topics/create#class&${classId}`)
  }

  createSubtopics = () => {
    const {
      history,
      location: { hash },
    } = this.props

    history.push(`/adminpanel#createSub&?${hash.split('?')[1]}`)
  }

  redirectBack = () => {
    const { history, loadMenu } = this.props

    loadMenu(process.env.REACT_APP_COURSE_ID || '2e033f1a-aa58-489f-85d2-0f92581b3624')

    history.push('/mainTopics/create')
  }

  openLesson = e => {
    const { lessonKey } = this.state

    this.setState({
      lessonKey: lessonKey.length ? '' : [e.key],
    })
  }

  openHover = e => {
    if (e.target.getAttribute('hoverurl')) {
      this.setState({
        hoverLink: e.target.getAttribute('hoverurl'),
      })
    }
  }

  closeHover = () => {
    this.setState({
      hoverLink: '',
    })
  }

  showMenu = () => {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu,
    }))
  }

  render() {
    const { data, keys, lessonKey, hoverLink, showMenu } = this.state
    const {
      topic,
      lessonsProgress,
      location: { pathname },
    } = this.props

    return (
      <div className={styles.menu}>
        <Button
          onClick={this.showMenu}
          className={showMenu ? styles.maintopic__button : styles.menu__control}
        >
          {showMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Button>
        {(pathname === '/mainTopics/create' ||
          pathname === '/mainTopics' ||
          pathname === '/classes/create' ||
          pathname === '/classes' ||
          pathname === '/topics/create' ||
          pathname === '/topics' ||
          pathname === '/footer/change' ||
          pathname === '/users' ||
          pathname === '/users/create' ||
          pathname === '/users/list') && (
          <div style={{ display: showMenu ? 'inline' : 'none' }}>
            <Button onClick={this.openUserList} className={styles.maintopic__button}>
              User Management
            </Button>
            <Button onClick={this.createMainTopic} className={styles.maintopic__button}>
              <PlusOutlined />
              Rubrik hinzufügen
            </Button>
            <Button onClick={this.changeFooter} className={styles.maintopic__button}>
              Footer ändern
            </Button>
            <Menu className={styles.short__menu} mode="inline">
              {data?.map(submenu => {
                return (
                  <SubMenu
                    key={submenu.id}
                    className={styles.maintopic__wrapper}
                    onTitleClick={this.openMainTopic}
                    title={<h3 className={styles.maintopic}>{submenu.name}</h3>}
                  >
                    {submenu.classes?.map(item => {
                      return (
                        <SubMenu
                          key={item.id}
                          className={styles.class_admin_wrapper}
                          onTitleClick={this.openClass}
                          title={<h3 className={styles.maintopic}>{item.name}</h3>}
                        >
                          {item.topics.map(topic => {
                            return (
                              <Menu.Item onClick={this.openTopicAdmin} key={topic.id}>
                                <h4 className={styles.topic_admin_wrapper} key={topic.id}>
                                  {topic.name}
                                </h4>
                              </Menu.Item>
                            )
                          })}
                        </SubMenu>
                      )
                    })}
                  </SubMenu>
                )
              })}
            </Menu>
          </div>
        )}
        {pathname === '/adminpanel' && (
          <div style={{ display: showMenu ? 'inline' : 'none' }}>
            <Button
              onClick={this.redirectBack}
              type={'primary'}
              className={styles.maintopic__button}
            >
              <LeftOutlined />
              Zurück
            </Button>
            <Button onClick={this.createSubtopics} className={styles.maintopic__button}>
              <PlusOutlined />
              Unterthema erstellen
            </Button>
            <Menu className={styles.short__menu} mode="inline" openKeys={keys}>
              {data?.map(subtopics => {
                return (
                  <SubMenu
                    key={`sub&${subtopics.id}`}
                    className={styles.maintopic__wrapper}
                    onTitleClick={this.openAdmin}
                    title={<h2 className={styles.maintopic}>{subtopics.name}</h2>}
                  >
                    {subtopics.lessons.map(lesson => {
                      return (
                        <SubMenu
                          key={`lesson&${lesson.id}`}
                          className={styles.maintopic__wrapper}
                          onTitleClick={this.openAdmin}
                          title={<h2 className={styles.maintopic}>{lesson.name}</h2>}
                        >
                          {lesson.exercises.map(exercise => {
                            return (
                              <Menu.Item onClick={this.openAdmin} key={`exercise&${exercise.id}`}>
                                <h3
                                  className={styles.maintopic__sub}
                                  key={`exercise&${exercise.id}`}
                                >
                                  {exercise.name}
                                </h3>
                              </Menu.Item>
                            )
                          })}
                        </SubMenu>
                      )
                    })}
                  </SubMenu>
                )
              })}
            </Menu>
          </div>
        )}
        {pathname === '/main' && (
          <div style={{ display: showMenu ? 'inline' : 'none' }}>
            <div
              style={{
                backgroundImage: `url(${hoverLink})`,
                display: hoverLink ? 'inline' : 'none',
              }}
              className={styles.hover__image}
            ></div>
            <Menu className={styles.main__menu} mode="inline">
              {data?.map(submenu => {
                return (
                  <SubMenu
                    onMouseOver={this.openHover}
                    onMouseOut={this.closeHover}
                    key={submenu.id}
                    className={styles.maintopic__wrapper}
                    title={
                      <h2
                        hoverurl={submenu.mediaItem && submenu.mediaItem.url}
                        className={styles.maintopic}
                      >
                        {submenu.name}
                      </h2>
                    }
                  >
                    {submenu.classes.map(item => {
                      return (
                        <Menu.Item onClick={this.openTopic} key={item.id}>
                          <h3 className={styles.maintopic__sub} key={item.id}>
                            {item.name}
                          </h3>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )
              })}
            </Menu>
          </div>
        )}
        {pathname === '/subtopics' && (
          <Menu
            onClick={this.handleClick}
            className={data?.length === 0 || !showMenu ? '' : styles.main__menu}
            mode="inline"
            defaultSelectedKeys={['1']}
          >
            <h3 className={styles.sub__title}>{topic.data.name}</h3>
            {data?.map(subtopic => {
              return (
                <Menu.Item onClick={this.openLessons} className={styles.submenu} key={subtopic.id}>
                  <h3 className={styles.subtopic__menu__wrapper} key={subtopic.id}>
                    {subtopic.name}
                  </h3>
                </Menu.Item>
              )
            })}
          </Menu>
        )}
        {pathname === '/lessons' && (
          <Menu
            className={data.length === 0 || !showMenu ? '' : styles.main__menu}
            mode="inline"
            selectedKeys={keys}
            openKeys={lessonKey}
          >
            {data?.map((lesson, index) => {
              return (
                <SubMenu
                  key={lesson.id}
                  className={styles.lessons}
                  onTitleClick={this.openLesson}
                  title={
                    <div className={styles.lesson__menu__wrapper}>
                      <h2>{lesson.name}</h2>
                      {lessonsProgress.data[index] && lessonsProgress.data[index].percentage > 95 && (
                        <Button
                          value={lesson.id}
                          onClick={this.restartLesson}
                          className={styles.reload__button}
                        >
                          <ReloadOutlined />
                        </Button>
                      )}
                    </div>
                  }
                >
                  {lesson.exercises.map((exercise, idx) => {
                    return (
                      <Menu.Item
                        onClick={this.openExercise}
                        key={exercise.id}
                        keyss={exercise.id}
                        className={styles.exercise__wrapper}
                        // disabled={(lessonsProgress.data[index] && lessonsProgress.data[index].exerciseProgresses[idx].userHasAccess) ? false : true}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h3 className={styles.maintopic__sub} key={exercise.id}>
                            {exercise.name}
                          </h3>
                          {exercise.type === 'TEST' && (
                            <Button
                              value={exercise.id}
                              onClick={this.restartExercise}
                              className={styles.reload__subbutton}
                            >
                              <ReloadOutlined />
                            </Button>
                          )}
                        </div>
                      </Menu.Item>
                    )
                  })}
                  {lessonsProgress.data[index] && (
                    <div className={styles.bottom__progress}>
                      <div className={styles.progress__text}>
                        Fortschritt:{' '}
                        {isNaN(lessonsProgress.data[index].percentage)
                          ? 0
                          : Math.round(Number.parseInt(lessonsProgress.data[index].percentage))}
                        %
                      </div>
                      <div className={styles.progress__line}>
                        <Progress
                          percent={Math.round(
                            Number.parseInt(lessonsProgress.data[index].percentage),
                          )}
                          size="small"
                          showInfo={false}
                        />
                      </div>
                    </div>
                  )}
                </SubMenu>
              )
            })}
          </Menu>
        )}
      </div>
    )
  }
}

export default AppMenu
