import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import userApi from 'services/users'
import adminApi from 'services/admin'
import notificationMessage from 'services/notification'
import { userDataSelector } from 'pages/usersList/utils'
import { actions as usersActions } from 'redux/lmsUsers/actions'
import { actions } from './actions'

export function* loadUser(userId) {
  try {
    const response = yield call(userApi.loadUser, userId.payload)

    yield put({
      type: actions.LOAD_USER_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_USER_RESULT,
      error,
    })
  }
}

export function* changeUserStatus({ payload: { userId, status } }) {
  try {
    yield call(adminApi.changeUserStatus, { userId, status })

    const users = yield select(userDataSelector)
    users.data = users.data.map(user => {
      if (user.id === userId) {
        user.status = status
      }
      return user
    })

    yield put({
      type: usersActions.LOAD_USERS_RESULT,
      payload: users,
    })

    const message = status === 'ACTIVE' ? 'User has been released' : 'User has been put On hold'

    notificationMessage('User status update', message)
  } catch (error) {
    notificationMessage('Error', `${error.response.data.message}`, true)
  }
}

export function* deleteUser({ payload: { userId } }) {
  try {
    yield call(adminApi.deleteUser, userId)

    const users = yield select(userDataSelector)
    users.data = users.data.filter(user => user.id !== userId)

    yield put({
      type: usersActions.LOAD_USERS_RESULT,
      payload: users,
    })

    notificationMessage('User has been deleted')
  } catch (error) {
    notificationMessage('Error', `${error.response.data.message}`, true)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USER, loadUser),
    takeEvery(actions.CHANGE_USER_STATUS, changeUserStatus),
    takeEvery(actions.DELETE_USER, deleteUser),
  ])
}
