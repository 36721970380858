export const actions = {
  LOAD_PROFILE_REQUEST: 'users/LOAD_PROFILE_REQUEST',
  LOAD_PROFILE_RESULT: 'users/LOAD_PROFILE_RESULT',
}

export function loadProfile(payload) {
  return {
    type: actions.LOAD_PROFILE_REQUEST,
    payload,
  }
}
