export const actions = {
  LOAD_FOOTER_REQUEST: 'users/LOAD_FOOTER_REQUEST',
  LOAD_FOOTER_RESULT: 'users/LOAD_FOOTER_RESULT',
}

export function loadFooter(payload) {
  return {
    type: actions.LOAD_FOOTER_REQUEST,
    payload,
  }
}
