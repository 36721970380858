import { all, takeEvery, put, call } from 'redux-saga/effects'
import adminApi from 'services/admin'
import { actions } from './actions'

export function* loadClass(classId) {
  try {
    const response = yield call(adminApi.getClass, classId.payload)

    yield put({
      type: actions.LOAD_CLASS_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_CLASS_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_CLASS_REQUEST, loadClass)])
}
