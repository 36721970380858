import addressApi from './axiosClient'

export default {
  createMainTopic: data => {
    return addressApi.post(`main-topics`, data)
  },
  updateMainTopic: (id, data) => {
    return addressApi.put(`main-topics/${id}`, data)
  },
  deleteMainTopic: id => {
    return addressApi.delete(`main-topics/${id}`)
  },
  getMainTopic: id => {
    return addressApi.get(`main-topics/${id}`)
  },
  createClass: data => {
    return addressApi.post(`classes`, data)
  },
  updateClass: (id, data) => {
    return addressApi.put(`classes/${id}`, data)
  },
  deleteClass: id => {
    return addressApi.delete(`classes/${id}`)
  },
  getClass: id => {
    return addressApi.get(`classes/${id}`)
  },
  createTopic: data => {
    return addressApi.post(`topics`, data)
  },
  updateTopic: (id, data) => {
    return addressApi.put(`topics/${id}`, data)
  },
  deleteTopic: id => {
    return addressApi.delete(`topics/${id}`)
  },
  getTopic: id => {
    return addressApi.get(`topics/${id}`)
  },
  getSub: id => {
    return addressApi.get(`subtopics/${id}`)
  },
  updateSub: (id, data) => {
    return addressApi.put(`subtopics/${id}`, data)
  },
  deleteSub: id => {
    return addressApi.delete(`subtopics/${id}`)
  },
  createSub: data => {
    return addressApi.post(`subtopics`, data)
  },
  getLesson: id => {
    return addressApi.get(`lessons/${id}`)
  },
  updateLesson: (id, data) => {
    return addressApi.put(`lessons/${id}`, data)
  },
  deleteLesson: id => {
    return addressApi.delete(`lessons/${id}`)
  },
  createLesson: data => {
    return addressApi.post(`lessons`, data)
  },
  getExercise: id => {
    return addressApi.get(`exercises/${id}`)
  },
  updateExercise: (id, data) => {
    return addressApi.put(`exercises/${id}`, data)
  },
  deleteExercise: id => {
    return addressApi.delete(`exercises/${id}`)
  },
  createExercise: data => {
    return addressApi.post(`exercises`, data)
  },
  getQuestions: id => {
    return addressApi.get(`questions`, { params: { exerciseId: id, size: 50 } })
  },
  getQuestion: id => {
    return addressApi.get(`questions/${id}`)
  },
  updateQuestion: (id, data) => {
    return addressApi.put(`questions/${id}`, data)
  },
  deleteQuestion: id => {
    return addressApi.delete(`questions/${id}`)
  },
  createQuestion: data => {
    return addressApi.post(`questions`, data)
  },
  getFooters: () => {
    return addressApi.get(`footers`)
  },
  getFooter: id => {
    return addressApi.get(`footers/${id}`)
  },
  updateFooter: (id, data) => {
    return addressApi.put(`footers/${id}`, data)
  },
  deleteFooter: id => {
    return addressApi.delete(`footers/${id}`)
  },
  createFooter: data => {
    return addressApi.post(`footers`, data)
  },
  createUser: data => {
    return addressApi.post(`users`, data)
  },
  changeUserStatus: data => {
    return addressApi.put(`users/${data.userId}/statuses?status=${data.status}`)
  },
  updateUser: (id, data) => {
    return addressApi.put(`users/${id}`, data)
  },
  deleteUser: id => {
    return addressApi.delete(`users/${id}`)
  },
}
