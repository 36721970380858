import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  {
    path: '/user/login',
    component: loadable(() => import('pages/login/index.jsx')),
    exact: true,
  },
  {
    path: '/user/admin',
    component: loadable(() => import('pages/login/index.jsx')),
    exact: true,
  },
  {
    path: '/user/restore',
    component: loadable(() => import('pages/restore/index.jsx')),
    exact: true,
  },
  {
    path: '/user/reset',
    component: loadable(() => import('pages/reset/index.jsx')),
    exact: true,
  },
  {
    path: '/user/congratulation',
    component: loadable(() => import('pages/congratulation/index.jsx')),
    exact: true,
  },
  {
    path: '/main',
    component: loadable(() => import('pages/main/index.jsx')),
  },
  {
    path: '/subtopics',
    component: loadable(() => import('pages/subtopics/index.jsx')),
  },
  {
    path: '/lessons',
    component: loadable(() => import('pages/lessons/index.jsx')),
  },
  {
    path: '/mainTopics/create',
    component: loadable(() => import('pages/mainTopicPage/index.jsx')),
  },
  {
    path: '/mainTopics',
    component: loadable(() => import('pages/mainTopicPage/index.jsx')),
  },
  {
    path: '/classes/create',
    component: loadable(() => import('pages/lmsClassPage/index.jsx')),
  },
  {
    path: '/classes',
    component: loadable(() => import('pages/lmsClassPage/index.jsx')),
  },
  {
    path: '/topics/create',
    component: loadable(() => import('pages/topicPage/index.jsx')),
  },
  {
    path: '/topics',
    component: loadable(() => import('pages/topicPage/index.jsx')),
  },
  {
    path: '/footer/change',
    component: loadable(() => import('pages/changeFooter/index.jsx')),
  },
  {
    path: '/adminpanel',
    component: loadable(() => import('pages/adminpanel/index.jsx')),
  },
  {
    path: '/users/list',
    component: loadable(() => import('pages/usersList/index.jsx')),
    exact: true,
  },
  {
    path: '/users/create',
    component: loadable(() => import('pages/userPage/index.jsx')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('pages/userPage/index.jsx')),
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/main" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
