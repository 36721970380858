import axios from 'axios'
import { CookieStorage } from 'cookie-storage'

const cookieStorage = new CookieStorage()

const ApiAddressHost =
  (process.env && process.env.REACT_APP_API_ADDRESS) ||
  'https://api-dev.schablonify-latein.de/api/v1/'

const addressApi = axios.create({
  baseURL: ApiAddressHost,
  headers: {
    accept: 'application/json',
  },
})

const INTERCEPTOR = {
  resolve: config => {
    const storedToken = cookieStorage.getItem('accessToken')
    if (!config.headers.authorization && !!storedToken) {
      config.headers.Authorization = storedToken
    }
    return config
  },
  reject: error => {
    return Promise.reject(error)
  },
}

addressApi.interceptors.request.use(INTERCEPTOR.resolve, INTERCEPTOR.reject)

export default addressApi
