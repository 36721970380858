export const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGOUT: 'user/LOGOUT',
  RESEND_MAIL: 'user/RESEND_MAIL',
  RESEND_PASS: 'user/RESEND_PASS',
}

export function resendEmail(payload) {
  return {
    type: actions.RESEND_MAIL,
    payload,
  }
}

export function resendPass(payload) {
  return {
    type: actions.RESEND_PASS,
    payload,
  }
}

export function logOut() {
  return {
    type: actions.LOGOUT
  }
}