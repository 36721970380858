export const actions = {
  LOAD_TOPICS_REQUEST: 'users/LOAD_TOPICS_REQUEST',
  LOAD_TOPICS_RESULT: 'users/LOAD_TOPICS_RESULT',
}

export function loadTopics(payload) {
  return {
    type: actions.LOAD_TOPICS_REQUEST,
    payload,
  }
}
