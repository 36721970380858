import addressApi from './axiosClient'

export default {
  loadSubTopics: topicId => {
    return addressApi.get(`subtopics`, {
      params: {
        page: 1,
        size: 20,
        topicId,
      },
    })
  },
}
