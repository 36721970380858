export const actions = {
  LOAD_MENU_REQUEST: 'users/LOAD_MENU_REQUEST',
  LOAD_MENU_RESULT: 'users/LOAD_MENU_RESULT',
}

export function loadMenu(payload) {
  return {
    type: actions.LOAD_MENU_REQUEST,
    payload,
  }
}
