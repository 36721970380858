import addressApi from './axiosClient'

export default {
  loadProfile: () => {
    return addressApi.get(`users/profile`)
  },
  loadProfileSecret: () => {
    return addressApi.get(`users/client-secret`)
  },
}
