export const actions = {
  LOAD_EXERCISES_REQUEST: 'users/LOAD_EXERCISES_REQUEST',
  LOAD_EXERCISES_RESULT: 'users/LOAD_EXERCISES_RESULT',
}

export function loadExercises(payload) {
  return {
    type: actions.LOAD_EXERCISES_REQUEST,
    payload,
  }
}
