import { all, takeEvery, put, call } from 'redux-saga/effects'
import footerApi from 'services/footer'
import { actions } from './actions'

export function* loadFooter() {
  try {
    const response = yield call(footerApi.loadFooter)
    
    yield put({
      type: actions.LOAD_FOOTER_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_FOOTER_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FOOTER_REQUEST, loadFooter)])
}
