export const actions = {
  LOAD_TOPICPROGRESS_REQUEST: 'users/LOAD_TOPICPROGRESS_REQUEST',
  LOAD_TOPICPROGRESS_RESULT: 'users/LOAD_TOPICPROGRESS_RESULT',
}

export function loadProgress(payload) {
  return {
    type: actions.LOAD_TOPICPROGRESS_REQUEST,
    payload,
  }
}
