import { notification } from 'antd'

export default function notificationMessage(newMessage, newDescription, error) {
  if (error) {
    return notification.error({
      message: newMessage,
      description: newDescription,
    })
  }
  return notification.success({
    message: newMessage,
    description: newDescription,
  })
}
