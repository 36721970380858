export const actions = {
  LOAD_LESSONS_PROGRESS_REQUEST: 'users/LOAD_LESSONS_PROGRESS_REQUEST',
  LOAD_LESSONS_PROGRESS_RESULT: 'users/LOAD_LESSONS_PROGRESS_RESULT',
}

export function loadLessonsProgress(payload) {
  return {
    type: actions.LOAD_LESSONS_PROGRESS_REQUEST,
    payload,
  }
}
