import addressApi from './axiosClient'

export default {
  loadLessons: subtopicId => {
    return addressApi.get(`lessons`, {
      params: {
        page: 1,
        size: 20,
        subtopicId,
      },
    })
  },
  loadLessonsProgress: ids => {
    const promises = ids.map(id => {
      return addressApi.get(`lessons/${id}/progress`)
    })

    return Promise.all(promises).then(value => {
      const answer = value.map(lessons => {
        return lessons.data
      })

      return answer
    })
  },
  restartLesson: lessonId => {
    return addressApi.post(`/lessons/${lessonId}/restart`)
  },
}
