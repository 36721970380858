import { all, takeEvery, put, call } from 'redux-saga/effects'
import topicsApi from 'services/topics'
import { actions } from './actions'

export function* loadTopic(topicId) {
  try {
    const response = yield call(topicsApi.loadSubPdf, topicId.payload)

    yield put({
      type: actions.LOAD_TOPIC_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_TOPIC_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_TOPIC_REQUEST, loadTopic)])
}
