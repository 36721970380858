export const actions = {
  LOAD_USERS: 'users/LOAD_USERS',
  LOAD_USERS_RESULT: 'users/LOAD_USERS_RESULT',
}

export function loadUsers(payload) {
  return {
    type: actions.LOAD_USERS,
    payload,
  }
}
