export const actions = {
  LOAD_SUBTOPICS_REQUEST: 'users/LOAD_SUBTOPICS_REQUEST',
  LOAD_SUBTOPICS_RESULT: 'users/LOAD_SUBTOPICS_RESULT',
}

export function loadSubtopics(payload) {
  return {
    type: actions.LOAD_SUBTOPICS_REQUEST,
    payload,
  }
}
