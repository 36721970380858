import React from 'react'
import { connect } from 'react-redux'
import { loadFooter } from 'redux/footer/actions'
import styles from './style.module.scss'
/*eslint-disable */
@connect(
  ({ footer }) => ({
    footer
  }),
  {
    loadFooter,
  }
)
class Footer extends React.Component {
  state = {
    data: []
  }

  componentDidMount() {
    const { loadFooter } = this.props

    loadFooter()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const footer = nextProps.footer.data

    if (JSON.stringify(footer) !== JSON.stringify(prevState.data)) {
      return {
        data: footer,
      }
    } else {
      return {}
    }
  }

  render() {
    const { data } = this.state

    return (
      <div className={styles.footer}>
        {
          data.map(el => {
            return (
              <div key={el.header} className={styles.footer__column}>
                <h3>{el.header}</h3>
                <hr />
                {
                  el.mediaItem ? (
                    <div className={styles.column__image} style={{backgroundImage:`url(${el.mediaItem.url})`}}></div>
                  ) : (
                      <p>{el.text}</p>
                    )
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default Footer
