import React from 'react'
import { Button } from 'antd'
import moment from 'moment'

export const userDataSelector = state => state.users

const getActionButton = (user, changeStatus) => {
  const isActive = user.status === 'ACTIVE'

  return (
    <Button
      type="primary"
      style={{
        background: isActive ? '#1890ff' : '#52c41a',
        border: 'none',
      }}
      onClick={e => {
        e.stopPropagation()
        changeStatus(user.id, isActive ? 'ON_HOLD' : 'ACTIVE')
      }}
    >
      {isActive ? 'Pausieren' : 'Release'}
    </Button>
  )
}

const getDeleteButton = (user, deleteUser) => {
  return (
    <Button
      type="danger"
      style={{
        border: 'none',
      }}
      onClick={e => {
        e.stopPropagation()
        deleteUser(user.id)
      }}
    >
      Löschen
    </Button>
  )
}

export const getColumns = (changeStatus, deleteUser) => [
  {
    title: `Name der lernenden Person`,
    key: 'name',
    render: user => user.firstName && user.lastName && `${user.firstName} ${user.lastName}`,
  },
  {
    title: `Email der lernenden Person`,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: `Created`,
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: true,
    defaultSortOrder: 'descend',
    render: createdAt => moment(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: `Name (Vertragspartner/-in)`,
    key: 'partner_first_name,partner_last_name',
    sorter: true,
    render: user =>
      user.partnerFirstName &&
      user.partnerLastName &&
      `${user.partnerFirstName} ${user.partnerLastName}`,
  },
  {
    title: `Email (Vertragspartner/-in)`,
    dataIndex: 'partnerEmail',
    key: 'partnerEmail',
  },
  {
    title: 'Action',
    key: 'action',
    render: user => getActionButton(user, changeStatus),
  },
  {
    title: 'Löschen',
    key: 'löschen',
    render: user => getDeleteButton(user, deleteUser),
  },
]
