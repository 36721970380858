import React from 'react'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import * as moment from 'moment'
import styles from './style.module.scss'

@withRouter
class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Layout>
        <Layout.Content>
          <div className={styles.layout}>
            <div className={styles.content}>{children}</div>
            <div className={styles.footer}>
              <p>&copy; {moment().format('YYYY')} d.i.e. Wissensmakler GmbH</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/impressum/"
              >
                Impressum
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/agb-und-widerrufsrecht/  "
              >
                AGB
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/datenschutz/"
              >
                Datenschutz
              </a>
            </div>
            <div className={styles.footer__mobile}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/impressum/"
              >
                Impressum
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/agb-und-widerrufsrecht/"
              >
                AGB
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.schablonify.de/datenschutz/"
              >
                Datenschutz
              </a>
              <p>&copy; {moment().format('YYYY')} d.i.e. Wissensmakler GmbH</p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
