export const actions = {
  LOAD_PRINTABLE_REQUEST: 'users/LOAD_PRINTABLE_REQUEST',
  LOAD_PRINTABLE_RESULT: 'users/LOAD_PRINTABLE_RESULT',
}

export function loadPrintable(payload) {
  return {
    type: actions.LOAD_PRINTABLE_REQUEST,
    payload,
  }
}
