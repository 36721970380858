import addressApi from './axiosClient'

export default {
  loadExercises: id => {
    return addressApi.post(`exercises/${id}/assignments`)
  },
  trackVideo: (percentage, id) => {
    return addressApi.post(`assignments/events/progress`, {
      assignmentId: id,
      type: percentage > 98 ? 'COMPLETE' : 'PROGRESS',
      percentageViewed: percentage,
    })
  },
  checkTest: (answer, taskId) => {
    return addressApi.post(`assignments/tasks/${taskId}`, answer)
  },
  restartExercise: exerciseId => {
    return addressApi.post(`/exercises/${exerciseId}/restart`)
  },
}
