import { all, takeEvery, put, call } from 'redux-saga/effects'
import { CookieStorage } from 'cookie-storage'
import addressApi from 'services/user'
import regApi from 'services/signup'
import checkaddressApi from 'services/checkLogin'
import notificationMessage from '../../services/notification'
import { actions } from './actions'

const cookieStorage = new CookieStorage()

export function* login({ payload }) {
  const { email, password, courseId, admin } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  try {
    let response = {}

    if (admin) {
      response = yield call(addressApi.loginAdmin, email, password, courseId)
      cookieStorage.setItem('admin', true)
    } else {
      response = yield call(addressApi.login, email, password, courseId)
    }

    cookieStorage.setItem('accessToken', response.data.accessToken)
    cookieStorage.setItem('refreshToken', response.data.refreshToken)

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        name: 'User',
        role: 'user',
        admin,
        authorized: true,
      },
    })
    notificationMessage('Login', 'Du hast dich erfolgreich angemeldet')
  } catch (error) {
    notificationMessage('Error', ``, true)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        authorized: false,
      },
    })
  }
}

export function* CHECK_LOGIN() {
  if (cookieStorage.getItem('refreshToken')) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
    try {
      const token = {
        refreshToken: cookieStorage.getItem('refreshToken'),
      }
      const response = yield call(checkaddressApi.checkLogin, token)

      cookieStorage.setItem('accessToken', response.data.accessToken)
      cookieStorage.setItem('refreshToken', response.data.refreshToken)
      const admin = cookieStorage.getItem('admin')

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          name: 'User',
          role: 'user',
          admin,
          authorized: true,
        },
      })
    } catch (e) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          authorized: false,
        },
      })
    }
  }
}

export function* logout() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      authorized: false,
      loading: false,
    },
  })
}

export function* resendMail({ payload }) {
  try {
    yield call(regApi.activationCode, payload)

    notificationMessage('Mail resend', 'You have successfully resend mail')
  } catch (error) {
    notificationMessage('Mail resend Error', `${error.response.data.message}`, true)
  }
}

export function* resendPass({ payload }) {
  try {
    yield call(regApi.resetPass, payload)

    notificationMessage('Success resend', "We've sent the reset link")
  } catch (error) {
    notificationMessage('Error', `${error.response.data.message}`, true)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.RESEND_MAIL, resendMail),
    takeEvery(actions.RESEND_PASS, resendPass),
    CHECK_LOGIN(), // run once on app load to check user auth
  ])
}
