export const actions = {
  LOAD_TOPIC_REQUEST: 'admin/LOAD_TOPIC_REQUEST',
  LOAD_TOPIC_RESULT: 'admin/LOAD_TOPIC_RESULT',
  RESET_TOPIC: 'admin/RESET_TOPIC',
}

export function loadAdminTopic(payload) {
  return {
    type: actions.LOAD_TOPIC_REQUEST,
    payload,
  }
}

export function resetAdminTopic() {
  return {
    type: actions.RESET_TOPIC,
  }
}
