import { all, takeEvery, put, call } from 'redux-saga/effects'
import subTopicsApi from 'services/subTopics'
import { actions } from './actions'

export function* loadSubtopics(topicId) {
  try {
    const response = yield call(subTopicsApi.loadSubTopics, topicId.payload)

    yield put({
      type: actions.LOAD_SUBTOPICS_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_SUBTOPICS_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_SUBTOPICS_REQUEST, loadSubtopics)])
}
