export const actions = {
  LOAD_LESSONS_REQUEST: 'users/LOAD_LESSONS_REQUEST',
  LOAD_LESSONS_RESULT: 'users/LOAD_LESSONS_RESULT',
}

export function loadLessons(payload) {
  return {
    type: actions.LOAD_LESSONS_REQUEST,
    payload,
  }
}
