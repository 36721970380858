import addressApi from './axiosClient'

export default {
  loadTopics: classId => {
    return addressApi.get(`topics`, {
      params: {
        page: 1,
        size: 20,
        classId,
      },
    })
  },
  loadTopicsProgress: ids => {
    const promises = ids.map(id => {
      return addressApi.get(`topic/${id}/progress`)
    })

    return Promise.all(promises).then(value => {
      const answer = value.map(topic => {
        return topic.data
      })

      return answer
    })
  },
  loadSubPdf: async topicId => {
    const answer = await addressApi.get(`topics/${topicId}`)

    return answer
  },
}
