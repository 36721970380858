import { all } from 'redux-saga/effects'
import user from './user/sagas'
import footer from './footer/sagas'
import topics from './topics/sagas'
import topic from './topic/sagas'
import topicProgress from './topicProgress/sagas'
import subTopics from './subTopics/sagas'
import lessons from './lessons/sagas'
import profile from './profile/sagas'
import lessonsProgress from './lessonsProgress/sagas'
import menu from './menu/sagas'
import exercise from './exercises/sagas'
import mainTopic from './mainTopic/sagas'
import lmsClass from './class/sagas'
import adminTopic from './adminTopic/sagas'
import printable from './printable/sagas'
import users from './lmsUsers/sagas'
import lmsUser from './lmsUser/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    footer(),
    topics(),
    topic(),
    menu(),
    topicProgress(),
    subTopics(),
    lessons(),
    lessonsProgress(),
    exercise(),
    profile(),
    mainTopic(),
    lmsClass(),
    adminTopic(),
    printable(),
    users(),
    lmsUser(),
  ])
}
