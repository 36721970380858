import { actions } from './actions'

const initialState = {
  data: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_USER_RESULT:
      return { ...state, ...action.payload }
    case actions.RESET_USER:
      return { ...state, data: {} }
    default:
      return state
  }
}
