import { actions } from './actions'

const initialState = {
  data: {},
}

export default function mainTopicReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_MAIN_TOPIC_RESULT:
      return { ...state, data: action.payload.data }
    case actions.RESET_MAIN_TOPIC:
      return { ...state, data: {} }
    default:
      return state
  }
}
