import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import { CookieStorage } from 'cookie-storage'
import { logOut } from 'redux/user/actions'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const cookieStorage = new CookieStorage()

/* eslint-disable */
@connect(({ user }) => ({ user }), { logOut })
class ProfileMenu extends React.Component {
  exit = () => {
    const { logOut } = this.props

    cookieStorage.removeItem('accessToken')
    cookieStorage.removeItem('refreshToken')

    logOut()
  }

  render() {
    const { admin } = this.props.user

    const menu = (
      <Menu selectable={false}>
        {admin && (
          <Menu.Item>
            <Link to="/mainTopics/create" style={{ margin: 0, padding: 0, textAlign: 'center' }}>
              Admin-Panel
            </Link>
          </Menu.Item>
        )}
        <Menu.Item onClick={this.exit}>
          <p style={{ margin: 0, textAlign: 'center' }}>Ausloggen</p>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
