import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import footer from './footer/reducers'
import topics from './topics/reducers'
import topic from './topic/reducers'
import topicProgress from './topicProgress/reducers'
import subTopics from './subTopics/reducers'
import lessons from './lessons/reducers'
import profile from './profile/reducers'
import lessonsProgress from './lessonsProgress/reducers'
import exercise from './exercises/reducers'
import menu from './menu/reducers'
import mainTopic from './mainTopic/reducers'
import lmsClass from './class/reducers'
import adminTopic from './adminTopic/reducers'
import printable from './printable/reducers'
import users from './lmsUsers/reducers'
import lmsUser from './lmsUser/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    footer,
    topics,
    topic,
    topicProgress,
    menu,
    subTopics,
    lessons,
    lessonsProgress,
    exercise,
    mainTopic,
    lmsClass,
    adminTopic,
    profile,
    printable,
    users,
    lmsUser,
  })
