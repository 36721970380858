import { actions } from './actions'

const initialState = {
  data: [],
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_MENU_RESULT:
      return { ...state, data: action.payload.data }
    default:
      return state
  }
}
