import addressApi from './axiosClient'

export default {
  resetPass: data => {
    return addressApi.post(`users/send-reset-key`, data)
  },
  restore: data => {
    return addressApi.put(`/users/reset-password`, data)
  },
}
