import addressApi from './axiosClient'

export default {
  login: (email, password, courseId) =>
    addressApi.post('auth/email-login', {
      email,
      password,
      courseId,
    }),
  loginAdmin: (email, password) =>
    addressApi.post('auth/admin', {
      email,
      password,
    }),
}
