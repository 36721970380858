import { actions } from './actions'

const initialState = {
  data: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_USERS_RESULT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
