import { all, takeEvery, put, call } from 'redux-saga/effects'
import lessonsApi from 'services/lessons'
import { actions } from './actions'

export function* loadLessonsProgress(ids) {
  try {
    const response = yield call(lessonsApi.loadLessonsProgress, ids.payload)

    yield put({
      type: actions.LOAD_LESSONS_PROGRESS_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_LESSONS_PROGRESS_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_LESSONS_PROGRESS_REQUEST, loadLessonsProgress)])
}
