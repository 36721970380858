import addressApi from './axiosClient'

export default {
  loadUsers: payload => {
    return addressApi.get(`users`, {
      params: {
        page: payload.current,
        size: payload.size,
        sort: payload.sort,
        courseId: process.env.REACT_APP_COURSE_ID,
      },
    })
  },
  loadUser: payload => {
    return addressApi.get(`users/${payload}`)
  },
}
