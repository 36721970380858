import { all, takeEvery, put, call } from 'redux-saga/effects'
import settingsApi from 'services/settings'
import { actions } from './actions'

export function* loadProfile(topicId) {
  try {
    const response = yield call(settingsApi.loadProfile, topicId.payload)

    yield put({
      type: actions.LOAD_PROFILE_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_PROFILE_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_PROFILE_REQUEST, loadProfile)])
}
