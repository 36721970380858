import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Menu from 'components/Menu/index'
import TopBar from 'components/TopBar/index'
import Footer from 'components/Footer/index'
import styles from './style.module.scss'

@withRouter
@connect()
class MainLayout extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Layout>
        <BackTop />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative', display: 'flex'}}>
            <Menu />
            <div className={styles.utils__content}>{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
